import WholeDesignCardContainer from "../components/common/WholeDesignCardContainer";
import TopRankCardContainer from "../components/specific/designMarket/TopRankCardContainer";

const DesignMarket = () => {
    return (
        <>
            {/* <TopRankCardContainer category='작가'/>
            <TopRankCardContainer category='도면'/> */}
            <WholeDesignCardContainer />
        </>
    );
};

export default DesignMarket;
